<template>
  <section id="menu">
    <div class="bg"></div>
    <div class="container" ref="container">
      <img class="topimage" src="@/assets/images/energy.png" />
      <div class="content" ref="content">
        <div class="row">
          <div class="col big">
            <div class="head">{{ AppData.copies.menu.index[$route.query.l ? $route.query.l : 'pt'] }}</div>
            <div class="chapters" ref="scale2">
              <div class="chap" v-for="chap in AppData.copies.menu.chaps[$route.query.l ? $route.query.l : 'pt']"
                :key="chap.num">
                <div class="num">{{ chap.num }}</div>
                <div class="name" v-html="chap.name"></div>
              </div>
            </div>
          </div>

        </div>


        <div class="row rels">

          <div class="head">{{ AppData.copies.menu.rel[$route.query.l ? $route.query.l : 'pt'] }}</div>

          <div class="col" ref="scale">
            <div class="rel" v-for="(rel, index) in AppData.copies.menu.rels[$route.query.l ? $route.query.l : 'pt']"
              :key="rel.name">
              <div v-if="index == 0" class="files" ref="files" @mouseleave="openDownloads($event, index)">
                <div class="tit">{{ AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].tit }}</div>
                <div class="row top">
                  <a target="_blank"
                    :href="AppData.pdfLocation + AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].link1.pdf">{{
              AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].link1.label }}</a>
                  <svg id="a" data-name="Isolation Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.69 28.69">
                    <path
                      d="M14.35,0c5.79,0,14.34,8.55,14.34,14.34,0,5.79-8.55,14.34-14.35,14.34-5.79,0-14.34-8.55-14.34-14.35C0,8.55,8.55,0,14.35,0"
                      style="fill: #F26522; stroke-width: 0px;" />
                    <path
                      d="M7.55,12.92c.39,1.14,1.17,2.13,1.96,3.03.91,1.03,1.94,1.98,3.07,2.76.44.3.95.62,1.47.78s1.14-.21,1.6-.49c1.17-.7,2.22-1.64,3.15-2.63s1.89-2.13,2.34-3.45c.32-.92-1.14-1.31-1.45-.4-.42,1.22-1.46,2.3-2.35,3.19-.51.51-1.06,1.01-1.64,1.44-.26.19-.53.38-.81.54-.14.08-.29.15-.43.24-.06.04-.36.1-.12.1s-.05-.06-.12-.1c-.14-.09-.29-.15-.43-.24-.28-.17-.55-.35-.81-.54-.58-.44-1.12-.93-1.64-1.44-.89-.89-1.93-1.97-2.35-3.19-.31-.91-1.77-.52-1.45.4h0Z"
                      style="fill: #ffffff; stroke-width: 0px;" />
                  </svg>
                </div>
                <div class="row">
                  <a target="_blank"
                    :href="AppData.pdfLocation + AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].link2.pdf">{{
              AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].link2.label }}</a>
                  <svg id="a" data-name="Isolation Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.69 28.69">
                    <path
                      d="M14.35,0c5.79,0,14.34,8.55,14.34,14.34,0,5.79-8.55,14.34-14.35,14.34-5.79,0-14.34-8.55-14.34-14.35C0,8.55,8.55,0,14.35,0"
                      style="fill: #F26522; stroke-width: 0px;" />
                    <path
                      d="M7.55,12.92c.39,1.14,1.17,2.13,1.96,3.03.91,1.03,1.94,1.98,3.07,2.76.44.3.95.62,1.47.78s1.14-.21,1.6-.49c1.17-.7,2.22-1.64,3.15-2.63s1.89-2.13,2.34-3.45c.32-.92-1.14-1.31-1.45-.4-.42,1.22-1.46,2.3-2.35,3.19-.51.51-1.06,1.01-1.64,1.44-.26.19-.53.38-.81.54-.14.08-.29.15-.43.24-.06.04-.36.1-.12.1s-.05-.06-.12-.1c-.14-.09-.29-.15-.43-.24-.28-.17-.55-.35-.81-.54-.58-.44-1.12-.93-1.64-1.44-.89-.89-1.93-1.97-2.35-3.19-.31-.91-1.77-.52-1.45.4h0Z"
                      style="fill: #ffffff; stroke-width: 0px;" />
                  </svg>
                </div>
              </div>
              <a :href="AppData.pdfLocation + rel.pdf" target="_blank" @click="openDownloads($event, index)">
                <div class="name" v-html="rel.name"></div>
                <svg id="b" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.03 27.03">
                  <g id="c" data-name="Layer 1">
                    <g>
                      <path
                        d="M13.52,0c5.46,0,13.52,8.06,13.52,13.52,0,5.46-8.06,13.52-13.52,13.52-5.46,0-13.52-8.06-13.52-13.52C0,8.06,8.06,0,13.52,0"
                        style="fill: #f26f31; fill-rule: evenodd; stroke-width: 0px;" />
                      <path
                        d="M7.5,13.6c.35,1.01,1.05,1.88,1.74,2.67.83.95,1.77,1.82,2.81,2.54.38.26.83.57,1.29.69.39.1.87-.22,1.2-.42,1.07-.64,2.03-1.51,2.88-2.42s1.7-1.9,2.1-3.07c.13-.37-.46-.53-.58-.16-.42,1.23-1.42,2.29-2.32,3.19-.51.51-1.05.99-1.63,1.42-.25.18-.5.36-.76.51-.18.11-.51.36-.72.36s-.54-.25-.72-.36c-.26-.16-.52-.33-.76-.51-.58-.43-1.12-.91-1.62-1.42-.9-.9-1.9-1.96-2.32-3.19-.13-.37-.71-.21-.58.16h0Z"
                        style="fill: #fff; stroke-width: 0px;" />
                    </g>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile">
        <div class="langs">
          <p :class="$route.query.l ? '' : 'sel'" @click="this.changeLang('');">PT</p>
          <p :class="$route.query.l ? 'sel' : ''" @click="this.changeLang('en')">EN</p>
        </div>
        <div class="nav">
          <div class="bt sel" ref="bt-index" @touchend="mobileChangeNav('index')">{{
              AppData.copies.menu.index[$route.query.l ? $route.query.l : 'pt'] }}</div>
          <div class="bt" ref="bt-rels" @touchend="mobileChangeNav('rels')">{{ AppData.copies.menu.rel[$route.query.l ?
              $route.query.l : 'pt'] }}</div>
        </div>
        <div class="index" ref="mobile-index">
          <div class="chap" v-for="chap in AppData.copies.menu.chaps[$route.query.l ? $route.query.l : 'pt']"
            :key="chap.num">
            <div class="num">{{ chap.num }}</div>
            <div class="name" v-html="chap.name"></div>
          </div>
        </div>
        <div class="rels" ref="mobile-rels">
          <div class="rel" v-for="(rel, index) in AppData.copies.menu.rels[$route.query.l ? $route.query.l : 'pt']"
            :key="rel.name">

            <div v-if="index == 0" class="files" ref="filesmobile">

              <svg class="close" fill="none" height="16" viewBox="0 0 16 16" width="16" @touchend="openDownloadsMobile($event, index)">
                <path clip-rule="evenodd"
                  d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.29289 5.70711L6.58579 8L4.29289 10.2929L5.70711 11.7071L8 9.41421L10.2929 11.7071L11.7071 10.2929L9.41421 8L11.7071 5.70711L10.2929 4.29289L8 6.58579L5.70711 4.29289L4.29289 5.70711Z"
                  fill="#030708" fill-rule="evenodd" />
              </svg>

              <div class="tit">{{ AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].tit }}</div>
              <div class="row top">
                <a target="_blank" :href="AppData.pdfLocation + AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].link1.pdf">{{
              AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].link1.label }}</a>
                <svg id="a" data-name="Isolation Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.69 28.69">
                  <path
                    d="M14.35,0c5.79,0,14.34,8.55,14.34,14.34,0,5.79-8.55,14.34-14.35,14.34-5.79,0-14.34-8.55-14.34-14.35C0,8.55,8.55,0,14.35,0"
                    style="fill: #F26522; stroke-width: 0px;" />
                  <path
                    d="M7.55,12.92c.39,1.14,1.17,2.13,1.96,3.03.91,1.03,1.94,1.98,3.07,2.76.44.3.95.62,1.47.78s1.14-.21,1.6-.49c1.17-.7,2.22-1.64,3.15-2.63s1.89-2.13,2.34-3.45c.32-.92-1.14-1.31-1.45-.4-.42,1.22-1.46,2.3-2.35,3.19-.51.51-1.06,1.01-1.64,1.44-.26.19-.53.38-.81.54-.14.08-.29.15-.43.24-.06.04-.36.1-.12.1s-.05-.06-.12-.1c-.14-.09-.29-.15-.43-.24-.28-.17-.55-.35-.81-.54-.58-.44-1.12-.93-1.64-1.44-.89-.89-1.93-1.97-2.35-3.19-.31-.91-1.77-.52-1.45.4h0Z"
                    style="fill: #ffffff; stroke-width: 0px;" />
                </svg>
              </div>
              <div class="row">
                <a target="_blank" :href="AppData.pdfLocation + AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].link2.pdf">{{
              AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].link2.label }}</a>
                <svg id="a" data-name="Isolation Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.69 28.69">
                  <path
                    d="M14.35,0c5.79,0,14.34,8.55,14.34,14.34,0,5.79-8.55,14.34-14.35,14.34-5.79,0-14.34-8.55-14.34-14.35C0,8.55,8.55,0,14.35,0"
                    style="fill: #F26522; stroke-width: 0px;" />
                  <path
                    d="M7.55,12.92c.39,1.14,1.17,2.13,1.96,3.03.91,1.03,1.94,1.98,3.07,2.76.44.3.95.62,1.47.78s1.14-.21,1.6-.49c1.17-.7,2.22-1.64,3.15-2.63s1.89-2.13,2.34-3.45c.32-.92-1.14-1.31-1.45-.4-.42,1.22-1.46,2.3-2.35,3.19-.51.51-1.06,1.01-1.64,1.44-.26.19-.53.38-.81.54-.14.08-.29.15-.43.24-.06.04-.36.1-.12.1s-.05-.06-.12-.1c-.14-.09-.29-.15-.43-.24-.28-.17-.55-.35-.81-.54-.58-.44-1.12-.93-1.64-1.44-.89-.89-1.93-1.97-2.35-3.19-.31-.91-1.77-.52-1.45.4h0Z"
                    style="fill: #ffffff; stroke-width: 0px;" />
                </svg>
              </div>
            </div>

            <a :href="AppData.pdfLocation + rel.pdf" target="_blank" @touchend="openDownloadsMobile($event, index)">
              <div class="name" v-html="rel.name"></div>
              <svg id="b" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.03 27.03">
                <g id="c" data-name="Layer 1">
                  <g>
                    <path
                      d="M13.52,0c5.46,0,13.52,8.06,13.52,13.52,0,5.46-8.06,13.52-13.52,13.52-5.46,0-13.52-8.06-13.52-13.52C0,8.06,8.06,0,13.52,0"
                      style="fill: #f26f31; fill-rule: evenodd; stroke-width: 0px;" />
                    <path
                      d="M7.5,13.6c.35,1.01,1.05,1.88,1.74,2.67.83.95,1.77,1.82,2.81,2.54.38.26.83.57,1.29.69.39.1.87-.22,1.2-.42,1.07-.64,2.03-1.51,2.88-2.42s1.7-1.9,2.1-3.07c.13-.37-.46-.53-.58-.16-.42,1.23-1.42,2.29-2.32,3.19-.51.51-1.05.99-1.63,1.42-.25.18-.5.36-.76.51-.18.11-.51.36-.72.36s-.54-.25-.72-.36c-.26-.16-.52-.33-.76-.51-.58-.43-1.12-.91-1.62-1.42-.9-.9-1.9-1.96-2.32-3.19-.13-.37-.71-.21-.58.16h0Z"
                      style="fill: #fff; stroke-width: 0px;" />
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  computed: { ...mapGetters(['AppData']) },

  mounted: function () {
    window.addEventListener("resize", this.resize);
    this.showMobileMenu();
    this.resize();
  },

  methods: {
    openDownloads(e, idx) {
      if (idx == 0) {
        e.preventDefault();
      } else { return false; }

      let files = this.$refs.files[0];

      console.log(files)

      if (files.classList.contains('show')) {
        files.classList.remove('show');
      } else {
        files.classList.add('show');
      }
    },

    openDownloadsMobile(e, idx) {

      console.log('DOWNLOADS MOBILE', idx)

      if (idx == 0) {
        e.preventDefault();
      } else { return false; }

      let files = this.$refs.filesmobile[0];

      if (files.classList.contains('show')) {
        files.classList.remove('show');
      } else {
        files.classList.add('show');
      }
    },

    changeLang(_lang) {
      if (_lang == 'en') {
        this.$router.push({ path: '/', query: { l: 'en' } });
      } else {
        this.$router.push({ path: '/' });
      }

      //this.goHome();   

    },

    showMobileMenu() {
      this.$refs['mobile-index'].classList.add('show');
    },

    mobileChangeNav(area) {
      let index = this.$refs['mobile-index'];
      let rels = this.$refs['mobile-rels'];
      let btindex = this.$refs['bt-index'];
      let btrels = this.$refs['bt-rels'];

      switch (area) {
        case 'index':
          rels.classList.remove('show');
          index.classList.add('show');
          btindex.classList.add('sel');
          btrels.classList.remove('sel');
          break;
        case 'rels':
          rels.classList.add('show');
          index.classList.remove('show');
          btindex.classList.remove('sel');
          btrels.classList.add('sel');
          break;
      }
    },
    resize() {
      if (this.$refs.container) this.$refs.container.style.maxWidth = this.AppData.sectionWidth + 'px';


      var scale = window.innerWidth * .00058;
      if (scale > 1) scale = 1;

      if (this.$refs.scale) this.$refs.scale.style.transform = "scale(" + scale + ")";
      if (this.$refs.scale2) this.$refs.scale2.style.transform = "scale(" + scale + ")";
      //this.$refs.content.style.transform = "scale(" + this.AppData.containerScale + ")";
    }
  }
}
</script>


<style lang="scss">
#menu {
  position: fixed;
  z-index: 750;
  height: 100vh;
  width: 100%;
  top: 100px;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s;

  @media (max-height: 760px) {
    position: fixed;
    height: 100%;
  }

  @media (max-width: 760px) {
    top: 70px;
  }

  .bg {
    position: absolute;
    left: 0;
    top: calc(100vh + 30px);
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 0;
    transition: all .7s cubic-bezier(0, 0, .09, 1);
  }

  .container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: top;
    top: 0;
    background: #fce0d3;
    width: 100%;
    height: 100vh;
    font-family: 'Canaro';
    font-size: 20px;
    top: calc(100vh + 30px);
    z-index: 1;
    transition: all .7s cubic-bezier(0, 0, .09, 1);

    @media (max-width: 760px) {
      max-width: 100% !important;
    }

    .content {
      @media (max-width: 760px) {
        display: none;
      }
    }

    .mobile {
      display: none;
      margin-top: 0;

      @media (max-width: 760px) {
        display: block;
      }

      .langs {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        margin-bottom: 20px;

        p {
          margin: 0 5px;

          &.sel {
            border-bottom: 1px solid #F26F31;
          }
        }
      }

      .index {
        width: 80%;
        margin: 40px auto 0 auto;
        display: none;

        &.show {
          display: block;
        }

        .chap {
          display: inline-block;
          vertical-align: top;
          width: 50%;
          text-align: left;
          margin-bottom: 40px;
          font-size: 16px;

          .num {
            font-family: 'Canaro Bold';
            margin-bottom: 7px;
          }
        }
      }

      .nav {
        display: flex;
        justify-content: center;
        align-items: center;

        .bt {
          padding: 0 10px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #F26F31;
          font-size: 12px;
          border-radius: 20px;
          border: 1px solid #F26F31;
          margin: 0 10px;
          font-family: 'Canaro Bold';

          &.sel {
            background: #F26F31;
            color: #fff;
            pointer-events: none;
          }
        }
      }

      .rels {
        width: 85%;
        margin: 40px auto 0 auto;
        display: none;

        &.show {
          display: block;
        }

        .rel {
          display: inline-block;
          vertical-align: top;
          width: 50%;
          text-align: left;
          margin-bottom: 20px;
          font-size: 15px;
          line-height: 18px;
          padding: 0 5px;
          position: relative;

          a {
            text-decoration: none;
            color: #231F20;
          }

          &:first-child {
            z-index: 11;
          }

          .files {
            position: absolute;
            color: #231F20;
            font-family: 'Canaro Bold';
            font-size: 12px;
            background: #fff;
            border: 1px solid #F26522;
            border-radius: 10px;
            width: 230px;
            padding: 15px 0;
            left: 50%;
            transform: translate(calc(-50% + 30px), 0px);
            text-align: center;
            opacity: 0;
            pointer-events: none;
            transition: all .3s;

            @media (max-height: 790px) {
              margin-top: 20px;
            }

            .close {
              position: absolute;
              width: 20px;
              top: -20px;
              right: -6px;
              background: #fce0d3;
              border-radius: 50%;

              path {
                fill: #954b28;
              }
            }

            &.show {
              opacity: 1;
              transform: translate(calc(-50% + 30px), -40px);
              pointer-events: all;
            }

            .tit {
              margin-bottom: 10px;
              font-size: 13px;
            }

            .row {
              padding-top: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              max-width: 100%;

              a {
                color: #000;
                margin: 0;
                font-family: 'Canaro';
                text-decoration: none;

                &:hover {
                  color: #F26522;
                }
              }

              &.top {
                border-bottom: 1px solid #F26522;
                padding: 10px 0;
              }

              svg {
                height: 15px;
                margin-top: 0;
                margin-left: 10px
              }
            }
          }

          .name {

            span {
              font-family: 'Canaro Bold';
              text-transform: uppercase;
              display: block;
              margin-bottom: -10px;
            }
          }

          svg {
            height: 25px;
            margin-top: 10px;
          }
        }
      }
    }

    .topimage {
      width: 100%;
      margin-bottom: 70px;

      @media (max-width: 1390px),
      (max-height: 900px) {
        margin-bottom: 30px;
      }
    }

    .row {
      display: flex;
      max-width: 95%;
      margin: auto;
      justify-content: space-between;

      .head {
        border-bottom: 1px solid #000;
        margin-bottom: 50px;
        font-size: 20px;
        padding-bottom: 10px;

        @media (max-width: 1390px),
        (max-height: 900px) {
          margin-bottom: 20px;
          font-size: 16px;
        }
      }

      .col {
        &.big {

          width: 100%;

          @media (max-height: 900px) {
            //max-width: 100%;
            //margin-right: 0;
          }
        }
      }

      h2 {
        font-size: clamp(20px, 4vw, 100px);
        font-family: 'Canaro';
        margin-right: 30px;
        margin-top: -16px;
        display: none;

        @media (max-height: 900px) {}
      }

      &.rels {
        display: block;
        margin-top: 40px;

        @media (max-width: 1390px),
        (max-height: 900px) {
          margin-top: 20px;
        }

        .col {

          transform-origin: top left;
          white-space: nowrap;


          .rel {
            font-size: clamp(16px, 2.3vw, 18px);
            line-height: 25px;
            max-width: 230px;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 20px;
            margin-right: 30px;
            white-space: normal;
            position: relative;

            a {
              text-decoration: none;
              color: #231F20;
            }

            &:first-child {
              z-index: 11;
            }

            .files {
              position: absolute;
              color: #231F20;
              font-family: 'Canaro Bold';
              font-size: 12px;
              background: #fff;
              border: 1px solid #F26522;
              border-radius: 10px;
              width: 230px;
              padding: 15px 0;
              left: 50%;
              transform: translate(calc(-50% + 0px), 100px);
              text-align: center;
              opacity: 0;
              pointer-events: none;
              transition: all .3s;

              @media (max-height: 790px) {
                margin-top: 20px;
              }

              &.show {
                opacity: 1;
                transform: translate(calc(-50% + 0px), 60px);
                pointer-events: all;

                @media (max-height: 790px) {
                  transform: translate(calc(-50% + 0px), -80px);
                }
              }

              .tit {
                margin-bottom: 10px;
                font-size: 13px;
              }

              .row {
                padding-top: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 100%;

                a {
                  color: #000;
                  margin: 0;
                  font-family: 'Canaro';
                  text-decoration: none;

                  &:hover {
                    color: #F26522;
                  }
                }

                &.top {
                  border-bottom: 1px solid #F26522;
                  padding: 10px 0;
                }

                svg {
                  height: 15px;
                  margin-top: 0;
                  margin-left: 10px
                }
              }
            }


            @media (max-width: 1390px),
            (max-height: 900px) {
              //margin-right: clamp(10px, 5vw, 30px);
              //font-size: clamp(13px, 0.3vw, 15px);
              //line-height: 25px;
            }

            &:last-child {
              margin-right: 0;
            }

            .name {
              span {
                font-family: 'Canaro Bold';
                text-transform: uppercase;
                display: inline-block;

              }
            }


            svg {
              height: 20px;
              display: block;
              margin-top: 10px
            }
          }
        }
      }



      .chapters {

        transform-origin: top left;
        white-space: nowrap;

        .chap {
          display: inline-block;
          vertical-align: top;
          margin-right: 50px;
          font-size: 21px;
          margin-bottom: 30px;
          line-height: 25px;
          white-space: normal;

          @media (max-width: 1390px),
          (max-height: 900px) {

            display: inline-block;
            vertical-align: top;
            //margin-right: clamp(26px, 3vw, 20px);
            //font-size: clamp(15px, 0.3vw, 15px);
            //margin-bottom: clamp(40px, 3vw, 40px);
            //line-height: 25px;


          }

          &:last-child {
            margin-right: 0;
          }

          .num {
            font-family: 'Canaro Bold';
            margin-bottom: 10px
          }

          .name {

            span {
              font-family: 'Canaro Bold';
            }
          }
        }
      }
    }
  }

  &.open {
    pointer-events: all;
    opacity: 1;

    @media (max-height: 760px) {
      height: 100%;
    }

    .bg,
    .container {
      top: 0;
      opacity: 1;

      @media (max-height: 760px) {
        height: 100%;
      }

    }
  }
}
</style>
