<template>
  <section id="header">
    <div class="container">
      <div class="col">
        <svg class="logo" id="b" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.48 61.5"
          @click="goHome()">
          <g id="c" data-name="Layer 1">
            <g>
              <path
                d="M103.73,0c12.42,0,30.75,18.33,30.75,30.75,0,12.42-18.34,30.75-30.76,30.75-12.42,0-30.75-18.33-30.75-30.75,0-12.42,18.34-30.75,30.76-30.75"
                style="fill: #f26522; fill-rule: evenodd; stroke-width: 0px;" />
              <path
                d="M100.89,15.52c1.57,1.57,1.56,4.11,0,5.67l-3.36,3.36c-3.42,3.42-3.42,8.97,0,12.39,3.42,3.42,8.97,3.42,12.39,0,3.42-3.42,3.43-8.97,0-12.39l-.86-.86c-.29-.29-.75-.29-1.05,0l-2.74,2.74c-.29.29-.29.75,0,1.05.29.29.75.29,1.04,0l.78-.78c.13.12.33.34.33.34,2.05,2.04,2.05,5.36,0,7.41-2.05,2.05-5.37,2.05-7.41,0-2.05-2.05-2.05-5.36,0-7.41l12.15-12.15,9.17,9.17c3.69,3.69,3.69,9.68,0,13.37l-10.94,10.93c-3.69,3.69-9.67,3.69-13.36,0l-10.94-10.93c-3.69-3.69-3.69-9.67,0-13.37l11.66-11.66,3.12,3.12Z"
                style="fill: #fff; fill-rule: evenodd; stroke-width: 0px;" />
              <path
                d="M30.94,31.94v3.44h-4.9c-1.02,0-1.81-.77-1.81-1.72s.79-1.72,1.81-1.72h4.9ZM25.71,38.6h9.42v-10.42c0-3.99-2.31-5.97-6.92-5.94-1.82,0-4.13.23-6.95.72l.48,3.73c2.57-.39,4.58-.58,6.04-.58,2.11,0,3.15.75,3.15,2.21v.42h-5.23c-3.54,0-5.68,2.18-5.68,4.93s2.12,4.94,5.68,4.94"
                style="fill: #231f20; stroke-width: 0px;" />
              <path
                d="M16.65,22.36v15.75c0,5.2-3.21,8.6-9.09,8.6-1.4,0-3.25-.32-5.55-.94l.71-3.64c2.56.42,4.13.62,4.71.62,2.89,0,4.51-1.46,4.7-4.16h-4.64c-2.17,0-3.99-.75-5.39-2.21C.71,34.9,0,32.88,0,30.48c.03-5.03,2.89-8.11,7.79-8.11,4.09,0,7.64,0,8.86,0M4.41,30.48c0,3.07,1.56,4.38,3.8,4.38,1.52,0,2.91,0,3.93,0v-8.76h-3.61c-2.73,0-4.11,1.33-4.12,4.38"
                style="fill: #231f20; stroke-width: 0px;" />
              <rect x="39.55" y="15.35" width="4.51" height="23.25" style="fill: #231f20; stroke-width: 0px;" />
              <path
                d="M65.32,30.48c0,2.4-.71,4.42-2.11,5.91-1.4,1.46-3.21,2.21-5.39,2.21h-4.64v7.3h-4.51v-23.53h8.86c4.91,0,7.66,3.08,7.79,8.11M60.94,30.48c0-3.05-1.36-4.38-4.12-4.38h-3.64v8.76h3.97c2.24,0,3.79-1.33,3.79-4.38"
                style="fill: #231f20; stroke-width: 0px;" />
            </g>
          </g>
        </svg>
        <p class="title">{{ AppData.copies.title[$route.query.l ? $route.query.l : 'pt'] }}</p>
      </div>
      <div class="col right">
        <a href="#">
          <div class="downloads">
            <p class="download" @click="openDownloads()">Download IMR</p>
            <div class="files" ref="files" @mouseleave="openDownloads()">
              <svg class="close" fill="none" height="16" viewBox="0 0 16 16" width="16" @touchend="openDownloads()">
                <path clip-rule="evenodd"
                  d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.29289 5.70711L6.58579 8L4.29289 10.2929L5.70711 11.7071L8 9.41421L10.2929 11.7071L11.7071 10.2929L9.41421 8L11.7071 5.70711L10.2929 4.29289L8 6.58579L5.70711 4.29289L4.29289 5.70711Z"
                  fill="#030708" fill-rule="evenodd" />
              </svg>
              <div class="tit">{{ AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].tit }}</div>
              <div class="row top">
                <a target="_blank" :href="AppData.pdfLocation + AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].link1.pdf">{{
            AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].link1.label }}</a>
                <svg id="a" data-name="Isolation Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.69 28.69">
                  <path
                    d="M14.35,0c5.79,0,14.34,8.55,14.34,14.34,0,5.79-8.55,14.34-14.35,14.34-5.79,0-14.34-8.55-14.34-14.35C0,8.55,8.55,0,14.35,0"
                    style="fill: #F26522; stroke-width: 0px;" />
                  <path
                    d="M7.55,12.92c.39,1.14,1.17,2.13,1.96,3.03.91,1.03,1.94,1.98,3.07,2.76.44.3.95.62,1.47.78s1.14-.21,1.6-.49c1.17-.7,2.22-1.64,3.15-2.63s1.89-2.13,2.34-3.45c.32-.92-1.14-1.31-1.45-.4-.42,1.22-1.46,2.3-2.35,3.19-.51.51-1.06,1.01-1.64,1.44-.26.19-.53.38-.81.54-.14.08-.29.15-.43.24-.06.04-.36.1-.12.1s-.05-.06-.12-.1c-.14-.09-.29-.15-.43-.24-.28-.17-.55-.35-.81-.54-.58-.44-1.12-.93-1.64-1.44-.89-.89-1.93-1.97-2.35-3.19-.31-.91-1.77-.52-1.45.4h0Z"
                    style="fill: #ffffff; stroke-width: 0px;" />
                </svg>
              </div>
              <div class="row">
                <a target="_blank" :href="AppData.pdfLocation + AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].link2.pdf">{{
            AppData.copies.downloads[$route.query.l ? $route.query.l : 'pt'].link2.label }}</a>
                <svg id="a" data-name="Isolation Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.69 28.69">
                  <path
                    d="M14.35,0c5.79,0,14.34,8.55,14.34,14.34,0,5.79-8.55,14.34-14.35,14.34-5.79,0-14.34-8.55-14.34-14.35C0,8.55,8.55,0,14.35,0"
                    style="fill: #F26522; stroke-width: 0px;" />
                  <path
                    d="M7.55,12.92c.39,1.14,1.17,2.13,1.96,3.03.91,1.03,1.94,1.98,3.07,2.76.44.3.95.62,1.47.78s1.14-.21,1.6-.49c1.17-.7,2.22-1.64,3.15-2.63s1.89-2.13,2.34-3.45c.32-.92-1.14-1.31-1.45-.4-.42,1.22-1.46,2.3-2.35,3.19-.51.51-1.06,1.01-1.64,1.44-.26.19-.53.38-.81.54-.14.08-.29.15-.43.24-.06.04-.36.1-.12.1s-.05-.06-.12-.1c-.14-.09-.29-.15-.43-.24-.28-.17-.55-.35-.81-.54-.58-.44-1.12-.93-1.64-1.44-.89-.89-1.93-1.97-2.35-3.19-.31-.91-1.77-.52-1.45.4h0Z"
                    style="fill: #ffffff; stroke-width: 0px;" />
                </svg>
              </div>
            </div>
          </div>
          <svg @click="openDownloads()" id="a" data-name="Isolation Mode" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28.69 28.69">
            <path
              d="M14.35,0c5.79,0,14.34,8.55,14.34,14.34,0,5.79-8.55,14.34-14.35,14.34-5.79,0-14.34-8.55-14.34-14.35C0,8.55,8.55,0,14.35,0"
              style="fill: #f26522; stroke-width: 0px;" />
            <path
              d="M7.55,12.92c.39,1.14,1.17,2.13,1.96,3.03.91,1.03,1.94,1.98,3.07,2.76.44.3.95.62,1.47.78s1.14-.21,1.6-.49c1.17-.7,2.22-1.64,3.15-2.63s1.89-2.13,2.34-3.45c.32-.92-1.14-1.31-1.45-.4-.42,1.22-1.46,2.3-2.35,3.19-.51.51-1.06,1.01-1.64,1.44-.26.19-.53.38-.81.54-.14.08-.29.15-.43.24-.06.04-.36.1-.12.1s-.05-.06-.12-.1c-.14-.09-.29-.15-.43-.24-.28-.17-.55-.35-.81-.54-.58-.44-1.12-.93-1.64-1.44-.89-.89-1.93-1.97-2.35-3.19-.31-.91-1.77-.52-1.45.4h0Z"
              style="fill: #fff; stroke-width: 0px;" />
          </svg>
        </a>
        <div class="menu" @click="openMenu($event, false)" ref="menu">
          <div class="label">
            <p class="open">{{ AppData.copies.index[$route.query.l ? $route.query.l : 'pt'] }}</p>
            <p class="close hide">Fechar</p>
          </div>

          <div class="handle" ref="handle">
            <div class="line"></div>
          </div>
        </div>
        <div class="langs">
          <p :class="$route.query.l ? '' : 'sel'" @click="this.changeLang('');">PT</p>
          <p :class="$route.query.l ? 'sel' : ''" @click="this.changeLang('en')">EN</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  computed: { ...mapGetters(['AppData']) },

  methods: {

    changeLang(_lang) {
      if (_lang == 'en') {
        this.$router.push({ path: '/', query: { l: 'en' } });
      } else {
        this.$router.push({ path: '/' });
      }

      //this.goHome();   

    },

    openDownloads() {
      let files = this.$refs.files;

      if (files.classList.contains('show')) {
        files.classList.remove('show');
      } else {
        files.classList.add('show');
      }
    },

    goHome() {
      let menu = document.getElementById('menu');

      this.$emit('changeArea', 0, 1, false);

      if (menu.classList.contains('open')) this.openMenu(null, true);
    },

    // eslint-disable-next-line
    openMenu(evt, _forceClose) {
      let menu = document.getElementById('menu');

      if (menu.classList.contains('open') || _forceClose) {
        menu.classList.remove('open');
        this.$refs.handle.classList.remove('sel');
        this.$refs.menu.querySelector('.open').classList.remove('hide');
        this.$refs.menu.querySelector('.close').classList.add('hide');
        this.$refs.handle.classList.remove('orange');

        console.log('CLOSE MENU', this.AppData.areaNumber);

        if (this.AppData.areaNumber == 11) this.goHome();

      } else {
        menu.classList.add('open');
        this.$refs.handle.classList.add('sel');
        this.$refs.menu.querySelector('.open').classList.add('hide');
        this.$refs.menu.querySelector('.close').classList.remove('hide');
        this.$refs.handle.classList.add('orange');
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#header {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'Canaro';
  font-size: 17px;
  background: #fff;
  position: relative;
  z-index: 1000;

  @media (max-width: 760px) {
    height: 70px;
    position: fixed;
  }

  .container {
    display: flex;
    justify-content: space-between;
    max-width: 2500px;
    margin: auto;
    width: 100%;


    .col {
      display: flex;
      align-items: center;

      &.right {
        font-size: 15px;

        @media (max-width: 960px) {
          font-size: 14px;
        }
      }
    }

    .title {
      @media (max-width: 1100px) {
        font-size: 15px;
      }

      @media (max-width: 960px) {
        display: none;
      }
    }

    .logo {
      width: 112px;
      margin-right: 75px;
      cursor: pointer;

      @media (max-width: 1100px) {
        margin-right: 30px;
      }

      @media (max-width: 960px) {
        width: 82px;
      }
    }

    .downloads {
      position: relative;

      .download {
        @media (max-width: 960px) {
          margin-right: 6px;
          position: relative;
          top: 1px;
        }
      }

      .files {
        position: absolute;
        color: #000;
        font-family: 'Canaro Bold';
        font-size: 12px;
        background: #fff;
        border: 1px solid #F26522;
        border-radius: 10px;
        width: 230px;
        padding: 15px 0;
        left: 50%;
        transform: translate(-50%, 0);
        text-align: center;
        opacity: 0;
        pointer-events: none;
        transition: all .3s;

        @media (max-width: 640px) {
          transform: translate(calc(-50% - 10px), 0px);
        }

        &.show {
          opacity: 1;
          transform: translate(-50%, 20px);
          pointer-events: all;

          @media (max-width: 640px) {
            transform: translate(calc(-50% - 10px), 20px);
          }
        }

        .close {
          position: absolute;
          width: 20px;
          top: -10px;
          right: -6px;
          background: #fce0d3;
          border-radius: 50%;
          display: none;

          @media (max-width: 760px) {
            display: block;
          }

          path {
            fill: #954b28;
          }
        }

        .tit {
          margin-bottom: 10px;
          font-size: 13px;
        }

        .row {
          padding-top: 15px;
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            color: #000;
            margin: 0;
            font-family: 'Canaro';

            &:hover {
              color: #F26522;
            }
          }

          &.top {
            border-bottom: 1px solid #F26522;
            padding: 10px 0;
          }

          svg {
            height: 15px;
          }
        }
      }

    }



    a {
      color: #231F20;
      text-decoration: none;
      display: flex;
      align-items: center;
      margin-right: 30px;

      svg {
        height: 20px;
        margin-left: 10px;

        @media (max-width: 960px) {
          margin-left: 0;
        }
      }

      @media (max-width: 960px) {
        margin-right: 20px;
      }
    }

    .menu {
      display: flex;
      align-items: center;
      margin-right: 30px;
      cursor: pointer;

      @media (max-width: 960px) {
        margin-right: 0;
      }

      .label {
        position: relative;
        width: 40px;

        @media (max-width: 960px) {
          display: none;
        }

        p {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: opacity .3s;

          &.close {
            color: #F26522;
          }

          &.hide {
            opacity: 0;
          }
        }
      }

      .handle {
        position: relative;
        display: inline-block;
        margin-left: 15px;
        width: 25px;
        height: 15px;
        cursor: pointer;
        z-index: 1;

        &:before,
        &:after,
        .line {
          content: "";
          position: absolute;
          left: 0;
          transition: all 0.3s;
          width: 100%;
          height: 2px;
          background-color: #000;
          display: inline-block;
        }

        @media (max-width: 960px) {
          margin-left: 0;
        }

        .line {
          top: 50%;
          transform: translateY(-50%);
        }

        &:before {
          top: 0;
        }

        &:after {
          bottom: 0;
        }

        &.sel {

          .line {
            opacity: 0;
          }

          &:before {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(135deg);
          }

          &:after {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-135deg);
          }
        }

        &.orange {

          &:before,
          &:after,
          .line {
            background-color: #F26522;
          }
        }
      }

      svg {
        height: 14px;
        margin-left: 13px;
      }
    }

    .langs {
      display: flex;
      align-items: center;

      @media (max-width: 960px) {
        display: none;
      }

      p {
        margin: 0 7px;
        position: relative;
        cursor: pointer;

        &:hover {
          color: #F26522;
        }

        a {
          color: #231F20;
          display: block;
          margin-right: 0;
        }

        &.sel {

          pointer-events: none;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: #F26522;
          }
        }
      }
    }
  }
}
</style>
