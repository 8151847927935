import { createWebHistory, createRouter } from 'vue-router'
import MainView from '../MainView.vue';

const routes = [
  { path: '/', component: MainView },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router