<template>
  <section id="nav">
    <ul :class="(AppData.areaNumber == 2 || AppData.areaNumber == 3) ? 'white' : 'black'">
      <li class="arrow" @click="this.$emit('changeArea', -1, false)">
        <svg id="b" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.56 51.56">
          <g id="c" data-name="Layer 1">
            <g>
              <path
                d="M25.77,51.56C15.36,51.55,0,36.19,0,25.78,0,15.37,15.37,0,25.78,0c10.41,0,25.77,15.37,25.77,25.78,0,10.41-15.37,25.78-25.78,25.78"
                style="fill: #D1CDCD; fill-rule: evenodd; stroke-width: 0px;" />
              <polygon points="33.29 40 33.29 36.8 16.56 25.78 33.29 14.75 33.29 11.55 11.71 25.78 33.29 40"
                style="fill: #fff; stroke-width: 0px;" />
            </g>
          </g>
        </svg>
      </li>
      <li :class="AppData.areaNumber == 1 ? 'sel' : ''" @click="this.$emit('changeArea', 0, 1, false)">
        <div class="label">{{ AppData.copies.navmessages[$route.query.l ? $route.query.l : 'pt'][0] }}</div>
      </li>
      <li :class="AppData.areaNumber == 2 ? 'sel' : ''" @click="this.$emit('changeArea', 0, 2, false)">
        <div class="label">{{ AppData.copies.navmessages[$route.query.l ? $route.query.l : 'pt'][1] }}</div>
      </li>
      <li :class="AppData.areaNumber == 3 ? 'sel' : ''" @click="this.$emit('changeArea', 0, 3, false)">
        <div class="label">{{ AppData.copies.navmessages[$route.query.l ? $route.query.l : 'pt'][2] }}</div>
      </li>
      <li :class="AppData.areaNumber == 4 ? 'sel' : ''" @click="this.$emit('changeArea', 0, 4, false)">
        <div class="label">{{ AppData.copies.navmessages[$route.query.l ? $route.query.l : 'pt'][3] }}</div>
      </li>
      <li :class="AppData.areaNumber == 5 ? 'sel' : ''" @click="this.$emit('changeArea', 0, 5, false)">
        <div class="label">{{ AppData.copies.navmessages[$route.query.l ? $route.query.l : 'pt'][4] }}</div>
      </li>
      <li :class="AppData.areaNumber == 6 ? 'sel' : ''" @click="this.$emit('changeArea', 0, 6, false)">
        <div class="label">{{ AppData.copies.navmessages[$route.query.l ? $route.query.l : 'pt'][5] }}</div>
      </li>
      <li :class="AppData.areaNumber == 7 ? 'sel' : ''" @click="this.$emit('changeArea', 0, 7, false)">
        <div class="label">{{ AppData.copies.navmessages[$route.query.l ? $route.query.l : 'pt'][6] }}</div>
      </li>
      <li :class="AppData.areaNumber == 8 ? 'sel' : ''" @click="this.$emit('changeArea', 0, 8, false)">
        <div class="label">{{ AppData.copies.navmessages[$route.query.l ? $route.query.l : 'pt'][7] }}</div>
      </li>
      <li :class="AppData.areaNumber == 9 ? 'sel' : ''" @click="this.$emit('changeArea', 0, 9, false)">
        <div class="label">{{ AppData.copies.navmessages[$route.query.l ? $route.query.l : 'pt'][8] }}</div>
      </li>
      <li :class="AppData.areaNumber == 10 ? 'sel' : ''" @click="this.$emit('changeArea', 0, 10, false)">
        <div class="label">{{ AppData.copies.navmessages[$route.query.l ? $route.query.l : 'pt'][9] }}</div>
      </li>
      <li :class="AppData.areaNumber == 11 ? 'sel' : ''" @click="this.$emit('changeArea', 0, 11, false)">
        <div class="label">{{ AppData.copies.navmessages[$route.query.l ? $route.query.l : 'pt'][10] }}</div>
      </li>
      <li class="arrow" @click="this.$emit('changeArea', 1, false)">
        <svg class="next" id="b" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.56 51.56">
          <g id="c" data-name="Layer 1">
            <g>
              <path
                d="M25.78,0c10.41,0,25.78,15.37,25.77,25.78,0,10.41-15.37,25.78-25.78,25.78-10.41,0-25.77-15.37-25.77-25.78C0,15.37,15.37,0,25.78,0"
                style="fill: #D1CDCD; fill-rule: evenodd; stroke-width: 0px;" />
              <polygon points="18.27 11.55 18.27 14.75 34.99 25.78 18.27 36.8 18.27 40 39.84 25.78 18.27 11.55"
                style="fill: #fff; stroke-width: 0px;" />
            </g>
          </g>
        </svg>
      </li>
    </ul>

  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: { ...mapGetters(['AppData']) },
}
</script>

<style scoped lang="scss">
#nav {
  position: absolute;
  left: 50%;
  width: 100%;
  bottom: 60px;
  transform: translateX(-50%);
  z-index: 500;

  @media (max-width: 960px) {
    display: none;
  }

  .wheel {
    
    position: absolute;
    right: 40px;
    bottom: -20px;
    text-align: center;

    svg {
      height: 30px;
      margin-bottom: 10px;
    }

    p {
      font-family: 'Canaro';
      font-size: 12px;
      color: #F26522;
    }
  }

  ul {
    max-width: 1200px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    align-items: center;
    margin: auto;
    position: relative;

    &:before {
      content: '';
      height: 1px;
      width: 100%;
      background-color: #D1CDCD;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    li {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: #D1CDCD;
      position: relative;
      transition: all .3s;
      cursor: pointer;

      .label {
        position: absolute;
        left: 50%;
        top: -18px;
        transform: translate(-50%, 50%);
        color: #000;
        opacity: 0;
        transition: all .2s;
        font-size: 7px;
        font-family: 'Canaro';
        width: 130px;
        text-align: center;
      }

      &:hover {
        .label {
          transform: translate(-50%, 50%);
          opacity: 1;
        }
      }

      svg {
        height: 30px;
        width: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        path {
          transition: all .3s;
        }

      }

      &.sel {
        background: #F26522;
      }

      &:hover {
        transform: scale(2);
      }

      &.arrow {
        background: none;

        &:hover {
          transform: scale(1.2);

          svg {
            path {
              fill: #F26522 !important;
            }
          }
        }
      }
    }

    &.white {
      li {
        .label {
          color: #fff;
          text-shadow: 1px 1px 2px #222;
        }
      }
    }
  }

}
</style>
