<template>
  <section class="section" id="home" ref="section">
    <div class="container" ref="container">
      <div class="top bigtext" ref="toptitle">Delivering</div>
      <div class="energy">
        <div class="letter le" ref="le">
          <img class="mask" src="@/assets/images/energy_letter_e.png" />
          <div class="overlay"></div>
          <div class="box"><img class="image" src="@/assets/images/energy_letter_e_image.jpg" /></div>
        </div>
        <div class="letter ln" ref="ln">
          <img class="mask" src="@/assets/images/energy_letter_n.png" />
          <div class="overlay"></div>
          <div class="box"><img class="image" src="@/assets/images/energy_letter_n_image.jpg" /></div>
        </div>
        <div class="letter le2" ref="le2">
          <img class="mask" src="@/assets/images/energy_letter_e.png" />
          <div class="overlay"></div>
          <div class="box"><img class="image" src="@/assets/images/energy_letter_e2_image.jpg" /></div>
        </div>
        <div class="letter lr" ref="lr">
          <img class="mask" src="@/assets/images/energy_letter_r.png" />
          <div class="overlay"></div>
          <div class="box"><img class="image" src="@/assets/images/energy_letter_r_image.jpg" /></div>
        </div>
        <div class="letter lg" ref="lg">
          <img class="mask" src="@/assets/images/energy_letter_g.png" />
          <div class="overlay"></div>
          <div class="box"><img class="image" src="@/assets/images/energy_letter_g_image.jpg" /></div>
        </div>
        <div class="letter ly" ref="ly">
          <img class="mask" src="@/assets/images/energy_letter_y.png" />
          <div class="overlay"></div>
          <div class="box"><img class="image" src="@/assets/images/energy_letter_y_image.jpg" /></div>
        </div>
      </div>
      <div class="bot bigtext" ref="bottitle">For life</div>
      <div class="logo" ref="logo">
        <svg id="b" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.48 61.5">
          <g id="c" data-name="Layer 1">
            <g>
              <path
                d="M103.73,0c12.42,0,30.75,18.33,30.75,30.75,0,12.42-18.34,30.75-30.76,30.75-12.42,0-30.75-18.33-30.75-30.75,0-12.42,18.34-30.75,30.76-30.75"
                style="fill: #f26522; fill-rule: evenodd; stroke-width: 0px;" />
              <path
                d="M100.89,15.52c1.57,1.57,1.56,4.11,0,5.67l-3.36,3.36c-3.42,3.42-3.42,8.97,0,12.39,3.42,3.42,8.97,3.42,12.39,0,3.42-3.42,3.43-8.97,0-12.39l-.86-.86c-.29-.29-.75-.29-1.05,0l-2.74,2.74c-.29.29-.29.75,0,1.05.29.29.75.29,1.04,0l.78-.78c.13.12.33.34.33.34,2.05,2.04,2.05,5.36,0,7.41-2.05,2.05-5.37,2.05-7.41,0-2.05-2.05-2.05-5.36,0-7.41l12.15-12.15,9.17,9.17c3.69,3.69,3.69,9.68,0,13.37l-10.94,10.93c-3.69,3.69-9.67,3.69-13.36,0l-10.94-10.93c-3.69-3.69-3.69-9.67,0-13.37l11.66-11.66,3.12,3.12Z"
                style="fill: #fff; fill-rule: evenodd; stroke-width: 0px;" />
              <path
                d="M30.94,31.94v3.44h-4.9c-1.02,0-1.81-.77-1.81-1.72s.79-1.72,1.81-1.72h4.9ZM25.71,38.6h9.42v-10.42c0-3.99-2.31-5.97-6.92-5.94-1.82,0-4.13.23-6.95.72l.48,3.73c2.57-.39,4.58-.58,6.04-.58,2.11,0,3.15.75,3.15,2.21v.42h-5.23c-3.54,0-5.68,2.18-5.68,4.93s2.12,4.94,5.68,4.94"
                style="fill: #231f20; stroke-width: 0px;" />
              <path
                d="M16.65,22.36v15.75c0,5.2-3.21,8.6-9.09,8.6-1.4,0-3.25-.32-5.55-.94l.71-3.64c2.56.42,4.13.62,4.71.62,2.89,0,4.51-1.46,4.7-4.16h-4.64c-2.17,0-3.99-.75-5.39-2.21C.71,34.9,0,32.88,0,30.48c.03-5.03,2.89-8.11,7.79-8.11,4.09,0,7.64,0,8.86,0M4.41,30.48c0,3.07,1.56,4.38,3.8,4.38,1.52,0,2.91,0,3.93,0v-8.76h-3.61c-2.73,0-4.11,1.33-4.12,4.38"
                style="fill: #231f20; stroke-width: 0px;" />
              <rect x="39.55" y="15.35" width="4.51" height="23.25" style="fill: #231f20; stroke-width: 0px;" />
              <path
                d="M65.32,30.48c0,2.4-.71,4.42-2.11,5.91-1.4,1.46-3.21,2.21-5.39,2.21h-4.64v7.3h-4.51v-23.53h8.86c4.91,0,7.66,3.08,7.79,8.11M60.94,30.48c0-3.05-1.36-4.38-4.12-4.38h-3.64v8.76h3.97c2.24,0,3.79-1.33,3.79-4.38"
                style="fill: #231f20; stroke-width: 0px;" />
            </g>
          </g>
        </svg>
      </div>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";
import { mapActions } from 'vuex';

export default {

  data() {
    return {
      animations: []
    }
  },

  mounted: function () {
    window.addEventListener("resize", this.resize);
    this.reset();
    this.resize();
  },

  methods: {
    ...mapActions(['updateAppData']),

    reset() {
      if(window.innerWidth < 760) return;
      gsap.to(this.$refs.toptitle, { x: -700, duration: 0, opacity: 0 });
      gsap.to(this.$refs.bottitle, { x: 600, duration: 0, opacity: 0 });
      gsap.to(this.$refs.logo, { x: 900, duration: 0, opacity: 0 });
      gsap.to(this.$refs.le, { y: 700, duration: 0, opacity: 0 });
      gsap.to(this.$refs.le.querySelector('.image'), { scale: 2, duration: 0 });
      gsap.to(this.$refs.ln, { y: -700, duration: 0, opacity: 0 });
      gsap.to(this.$refs.ln.querySelector('.image'), { scale: 2, duration: 0 });
      gsap.to(this.$refs.le2, { y: 700, duration: 0, opacity: 0 });
      gsap.to(this.$refs.le2.querySelector('.image'), { scale: 2, duration: 0 });
      gsap.to(this.$refs.lr, { y: -700, duration: 0, opacity: 0 });
      gsap.to(this.$refs.lr.querySelector('.image'), { scale: 2, duration: 0 });
      gsap.to(this.$refs.lg, { y: 700, duration: 0, opacity: 0 });
      gsap.to(this.$refs.lg.querySelector('.image'), { scale: 2, duration: 0 });
      gsap.to(this.$refs.ly, { y: -700, duration: 0, opacity: 0 });
      gsap.to(this.$refs.ly.querySelector('.image'), { scale: 2, duration: 0 });
    },
    animateIn() {
      if(window.innerWidth < 760) return;
      var duration = .8;
      var duration2 = 20;
      var ease = "power2.out";
      this.animations.push(gsap.to(this.$refs.le, { y: 0, duration: duration, opacity: 1, ease: ease }));
      this.animations.push(gsap.to(this.$refs.le.querySelector('.image'), { scale: 1.2, duration: duration2, ease: ease }));
      this.animations.push(gsap.to(this.$refs.ln, { y: 0, duration: duration, opacity: 2, delay: .2, ease: ease }));
      this.animations.push(gsap.to(this.$refs.ln.querySelector('.image'), { scale: 1.2, duration: duration2, delay: .2, ease: ease }));
      this.animations.push(gsap.to(this.$refs.le2, { y: 0, duration: duration, opacity: 1, delay: .3, ease: ease }));
      this.animations.push(gsap.to(this.$refs.le2.querySelector('.image'), { scale: 1.2, duration: duration2, delay: .3, ease: ease }));
      this.animations.push(gsap.to(this.$refs.lr, { y: 0, duration: duration, opacity: 1, delay: .4, ease: ease }));
      this.animations.push(gsap.to(this.$refs.lr.querySelector('.image'), { scale: 1.2, duration: duration2, delay: .4, ease: ease }));
      this.animations.push(gsap.to(this.$refs.lg, { y: 0, duration: duration, opacity: 1, delay: 0.5, ease: ease }));
      this.animations.push(gsap.to(this.$refs.lg.querySelector('.image'), { scale: 1.2, duration: duration2, delay: 0.5, ease: ease }));
      this.animations.push(gsap.to(this.$refs.ly, { y: 0, duration: duration, opacity: 1, delay: 0.6, ease: ease }));
      this.animations.push(gsap.to(this.$refs.ly.querySelector('.image'), { scale: 1.2, duration: duration2, delay: 0.6, ease: ease }));
      this.animations.push(gsap.to(this.$refs.toptitle, { x: 0, duration: 1, opacity: 1, delay: 0.7, ease: ease }));
      this.animations.push(gsap.to(this.$refs.bottitle, { x: 0, duration: 1, opacity: 1, delay: 0.8, ease: ease }));
      this.animations.push(gsap.to(this.$refs.logo, { x: 0, duration: 1, opacity: 1, delay: 0.9, ease: ease }));
      this.$refs.section.style.zIndex = 1;
    },

    animateOut() {
      if(window.innerWidth < 760) return;
      for (var i = 0; i < this.animations.length; i++) {
        this.animations[i].timeScale(4).reverse();
      }
    },

    resize() {
      var scale = Math.min(window.innerWidth / this.$refs.container.clientWidth, window.innerHeight / this.$refs.container.clientHeight) * .85;
      if (scale > .85) scale = .85;
      this.$refs.container.style.transform = "translate(-50%,-50%) scale(" + scale + ")";
      document.getElementById('header').querySelector('.container').style.maxWidth = this.$refs.container.getBoundingClientRect().width + 'px';

      this.updateAppData({ prop: 'sectionWidth', value: this.$refs.container.getBoundingClientRect().width });
      this.updateAppData({ prop: 'containerScale', value: scale });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#home {

  @media (max-width: 760px) {
    height: 100vh;
  }

  .container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: -40px;
  }

  .bigtext {
    font-family: 'Canaro Bold';
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: #F26522;
    font-size: 160px;
    margin-bottom: 50px;
    margin-left: -7px;

    &.bot {
      justify-content: flex-end;
      margin-bottom: 0;
      margin-top: 50px;
      padding-left: 0px;
      width: calc(100% - 58px);

      @media (max-width: 760px) {
        transform-origin: right;
        margin-top: 150px;
      }
    }

    @media (max-width: 760px) {
      opacity: 1 !important;
      transform: translate(0, 0) scale(1.3) !important;
      transform-origin: left;
      margin-bottom: 150px;
    }
  }

  .logo {
    width: calc(100% - 58px);
    display: flex;
    justify-content: flex-end;

    @media (max-width: 760px) {
      transform: scale(1.6) !important;
      transform-origin: right;
      margin-top: 80px;
    }

    svg {
      width: 326px;
    }
  }

  .energy {

    display: flex;
    justify-content: center;
    align-items: center;

    .letter {
      position: relative;
      overflow: hidden;
      margin: 0 25px;

      @media (max-width: 760px) {
        opacity: 1 !important;
        transform: translate(0, 0) !important;
      }

      &:first-child {
        margin-left: 0;
      }

      .overlay {
        position: absolute;
        left: 0;
        top: 0;
        background: #231F20;
        opacity: .15;
        width: 100%;
        height: 100%;
        z-index: 2;
      }

      .box {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 99%;
        height: 99%;
        overflow: hidden;
        transform: translate(-50%, -50%);
      }

      img {
        position: absolute;
        left: 0;
        top: 0;

        &.mask {
          z-index: 3;
        }

        &.image {
          z-index: 0;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
          z-index: 1;
        }
      }

      &.le {
        width: 358px;
        height: 476px;

        .image {
          transform: translate(-484px, -184.66px) scale(1.2);
        }
      }

      &.ln {
        width: 444px;
        height: 476px;

        .image {
          transform: translate(-364px, -224px) scale(1.2);
        }
      }

      &.le2 {
        width: 358px;
        height: 476px;

        .image {
          transform: translate(-304px, -234px) scale(1.06);
        }
      }

      &.lr {
        width: 427px;
        height: 476px;
        margin-right: 0;

        .image {
          transform: translate(-594px, -234px) scale(1.06);
        }
      }

      &.lg {
        width: 441px;
        height: 476px;
        margin-left: 0;

        .image {
          transform: translate(-396px, -234px) scale(1.06);
        }
      }

      &.ly {
        width: 499px;
        height: 476px;
        margin-left: 0;

        .image {
          transform: translate(-390px, -234px) scale(1.3);
        }
      }
    }

  }
}
</style>
