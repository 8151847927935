<template>
  <section class="section" id="filipe" ref="section">
    <div class="bg" ref="bg"></div>
    <img class="mobile imgmob" src="@/assets/images/filipe_silva_mobile.jpg" />
    <div class="container">
      <div class="content" ref="content">
        <h2 ref="title">{{ AppData.copies.filipe[$route.query.l ? $route.query.l : 'pt'].name }}</h2>
        <p class="sub" ref="subtitle">{{ AppData.copies.filipe[$route.query.l ? $route.query.l : 'pt'].title }}</p>
        <p ref="text">"{{ AppData.copies.filipe[$route.query.l ? $route.query.l : 'pt'].message }}"</p>
        <a :href="AppData.pdfLocation + AppData.copies.filipe[$route.query.l ? $route.query.l : 'pt'].pdf" ref="link" target="_blank">
          <span>{{ AppData.copies.filipe[$route.query.l ? $route.query.l : 'pt'].button }}</span>
          <svg id="b" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.56 51.56">
            <g id="c" data-name="Layer 1">
              <g>
                <path
                  d="M25.78,0c10.41,0,25.78,15.37,25.77,25.78,0,10.41-15.37,25.78-25.78,25.78-10.41,0-25.77-15.37-25.77-25.78C0,15.37,15.37,0,25.78,0"
                  style="fill: #f26522; fill-rule: evenodd; stroke-width: 0px;" />
                <polygon points="18.27 11.55 18.27 14.75 34.99 25.78 18.27 36.8 18.27 40 39.84 25.78 18.27 11.55"
                  style="fill: #fff; stroke-width: 0px;" />
              </g>
            </g>
          </svg>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      animations: []
    }
  },

  computed: { ...mapGetters(['AppData']) },

  mounted: function () {
    window.addEventListener("resize", this.resize);
    this.reset();
    this.resize();
  },

  methods: {

    reset() {
      if (window.innerWidth < 760) return;
      gsap.to(this.$refs.bg, { x: window.innerWidth, duration: 0, opacity: 0 });
      gsap.to(this.$refs.title, { x: window.innerWidth, duration: 0, opacity: 0 });
      gsap.to(this.$refs.subtitle, { x: window.innerWidth, duration: 0, opacity: 0 });
      gsap.to(this.$refs.text, { x: window.innerWidth, duration: 0, opacity: 0 });
      gsap.to(this.$refs.link, { x: window.innerWidth, duration: 0, opacity: 0 });
    },

    animateIn() {
      if (window.innerWidth < 760) return;
      this.animations.push(gsap.to(this.$refs.bg, { x: 0, duration: .6, opacity: 1, ease: "power1.out" }));
      this.animations.push(gsap.to(this.$refs.title, { duration: .6, opacity: 1, x: 0, delay: 0.3, ease: "power1.out" }));
      this.animations.push(gsap.to(this.$refs.subtitle, { duration: .6, opacity: 1, x: 0, delay: 0.4, ease: "power1.out" }));
      this.animations.push(gsap.to(this.$refs.text, { duration: .6, opacity: 1, x: 0, delay: 0.5, ease: "power1.out" }));
      this.animations.push(gsap.to(this.$refs.link, { duration: .6, opacity: 1, x: 0, delay: 0.6, ease: "power1.out" }));
      this.$refs.section.style.zIndex = 1;
    },

    animateOut(reverse) {
      if (window.innerWidth < 760) return;
      gsap.to(this.$refs.bg, { x: - window.innerWidth * reverse, duration: .6, opacity: 1, ease: "power1.out" });
      gsap.to(this.$refs.link, { x: - window.innerWidth * reverse, duration: .6, opacity: 0, ease: "power1.out" });
      gsap.to(this.$refs.text, { x: - window.innerWidth * reverse, duration: .6, opacity: 0, ease: "power1.out" });
      gsap.to(this.$refs.subtitle, { x: - window.innerWidth * reverse, duration: .6, opacity: 0, ease: "power1.out" });
      gsap.to(this.$refs.title, { x: - window.innerWidth * reverse, duration: .6, opacity: 0, ease: "power1.out" });
    },

    resize() {
      if (window.innerWidth < 760) return;
      this.$refs.content.style.transform = "translateY(-50%) scale(" + this.AppData.containerScale * .8 + ")";
      this.$refs.section.querySelector('.container').style.maxWidth = this.AppData.sectionWidth + 'px';
    }
  }
}
</script>

<style scoped lang="scss">
#filipe {

  .mobile {
    display: none;

    @media (max-width: 760px) {
      display: block;
    }
  }

  .imgmob {
    width: auto;
    height: 770px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url('@/assets/images/filipe_silva.jpg');
    background-position: center top;

    @media (max-width: 760px) {
      opacity: 1 !important;
      transform: translate(0, 0) !important;
      background-position-x: 990px;
      display: none;
    }
  }

  .container {
    margin: auto;
    width: 100%;
    height: calc(100vh - 140px);
    position: relative;

    @media (max-width: 760px) {
      max-width: 90% !important;
      background: #fff;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      padding: 20px;
      height: auto;
    }

    .content {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      color: #fff;
      transform-origin: left center;
      margin-top: -40px;
      max-width: 1000px;

      @media (max-height: 760px) {
        margin-top: -20px;
      }

      @media (max-width: 760px) {
        transform: translate(-50%, -50%) scale(1) !important;
        width: 85%;
        left: 50%;
        color: #222;
        position: relative;
        top: 0;
        transform: none !important;
        left: 0;
        width: 100%;
        margin: 0;  
      }

      h2 {
        font-family: 'Canaro Bold';
        font-size: 100px;
        text-shadow: 5px 7px 13px #474747;

        @media (max-width: 760px) {
          opacity: 1 !important;
          transform: translate(0, 0) !important;
          font-size: 27px;
          text-shadow: none;
        }
      }

      p {
        font-family: 'Canaro';
        font-size: 44px;
        line-height: 63px;
        text-shadow: 5px 7px 13px #222;

        &.sub {
          font-family: 'Canaro Bold';
          font-size: 31px;
          margin: 15px 0 80px 0;

          @media (max-width: 760px) {
            font-size: 13px;
            line-height: 20px;
            margin: 5px 0 30px 0;
          }
        }

        @media (max-width: 760px) {
          opacity: 1 !important;
          transform: translate(0, 0) !important;
          font-size: 14px;
          line-height: 20px;
          text-shadow: none;
        }
      }

      a {
        color: #fff;
        font-family: 'Canaro Bold';
        font-size: 34px;
        text-decoration: none;
        display: flex;
        align-items: center;
        margin-top: 80px;
        text-shadow: 5px 7px 13px #222;

        @media (max-width: 760px) {
          color: #fff;
        }

        svg {
          height: 50px;
          margin-left: 15px;

          @media (max-width: 760px) {
            height: 25px;
          }
        }

        @media (max-width: 760px) {
          opacity: 1 !important;
          transform: translate(0, 0) !important;
          font-size: 12px;
          margin-top: 40px;
          text-shadow: none;
          color: #222;
        }
      }
    }
  }
}
</style>
