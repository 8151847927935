<template>
  <MainHeader @changeArea="changeArea" ref="header" />
  <MainNavigation @changeArea="changeArea" />
  <ScreenHome ref="area1" />
  <ScreenPaula ref="area2" />
  <ScreenFilipe ref="area3" />
  <ScreenChapter1 ref="area4" />
  <ScreenChapter2 ref="area5" />
  <ScreenChapter3 ref="area6" />
  <ScreenChapter4 ref="area7" />
  <ScreenChapter5 ref="area8" />
  <ScreenChapter6 ref="area9" />
  <ScreenChapter7 ref="area10" />
  <MainMenu />
</template>

<script>
import MainHeader from './components/MainHeader.vue'
import MainMenu from './components/MainMenu.vue'
import MainNavigation from './components/MainNavigation.vue'
import ScreenHome from './components/ScreenHome.vue'
import ScreenPaula from './components/ScreenPaula.vue'
import ScreenFilipe from './components/ScreenFilipe.vue'
import ScreenChapter1 from './components/ScreenChapter1.vue'
import ScreenChapter2 from './components/ScreenChapter2.vue'
import ScreenChapter3 from './components/ScreenChapter3.vue'
import ScreenChapter4 from './components/ScreenChapter4.vue'
import ScreenChapter5 from './components/ScreenChapter5.vue'
import ScreenChapter6 from './components/ScreenChapter6.vue'
import ScreenChapter7 from './components/ScreenChapter7.vue'
import { mapGetters, mapActions } from 'vuex';

export default {

  components: { MainHeader, MainMenu, MainNavigation, ScreenHome, ScreenPaula, ScreenFilipe, ScreenChapter1, ScreenChapter2, ScreenChapter3, ScreenChapter4, ScreenChapter5, ScreenChapter6, ScreenChapter7 },
  computed: { ...mapGetters(['AppData']) },

  mounted() {
    window.addEventListener("resize", this.resize);
    this.setupEvents();
    this.changeArea(0, 1, true);
  },

  methods: {
    ...mapActions(['updateAppData']),

    setupEvents() {
      let view = this;
      window.addEventListener("wheel", this.onMouseWheel);
      document.addEventListener("keydown", function (event) {
        if(event.key == 'ArrowRight') {
          view.changeArea(1, false);
        } 
        if(event.key == 'ArrowLeft') {
          view.changeArea(-1, false);
        }
      });
    },

    onMouseWheel(event) {
      if (window.innerHeight > 600) this.changeArea(event.deltaY < 0 ? -1 : 1, false);
    },

    changeArea(_dir, _new, _firstTime) {

      if (window.innerWidth < 600) return;

      if (this.AppData.lockSite) return;
      this.updateAppData({ prop: 'lock', value: true });

      if (_dir < 0 && this.AppData.areaNumber == 1 || _dir > 0 && this.AppData.areaNumber == this.AppData.totalAreas) {
        this.updateAppData({ prop: 'lock', value: false });
        return
      }
      let next;
      if (_new) {
        if (_new == this.AppData.areaNumber && !_firstTime) {
          this.updateAppData({ prop: 'lock', value: false });
          return;
        }
        next = _new;
      } else {
        next = this.AppData.areaNumber + _dir;
      }

      if (_dir == 0 && next) {
        if (next > this.AppData.areaNumber) {
          _dir = 1;
        } else {
          _dir = -1;
        }
      }

      document.querySelectorAll('.section').forEach((section) => {
        section.style.zIndex = 0;
      });

      if (!_firstTime) {
        if(this.AppData.areaNumber < 11) this.$refs['area' + this.AppData.areaNumber].animateOut(_dir);
      }

      if(next < 11) {
        this.$refs['area' + next].animateIn();
      } else {
        this.$refs.header.openMenu();
      }
     
      this.updateAppData({ prop: 'aid', value: next });
      let view = this;

      setTimeout(function () {
        view.updateAppData({ prop: 'lock', value: false });
      }, 1000);
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Canaro';
  src: url('@/assets/fonts/Canaro-Medium.woff2') format('woff2'),
    url('@/assets/fonts/Canaro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Canaro Bold';
  src: url('@/assets/fonts/Canaro-Bold.woff2') format('woff2'),
    url('@/assets/fonts/Canaro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: auto;
}

body {
  font-family: 'Canaro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  color: #231F20;
  background: #f3f3f3;
  overflow: hidden;
  height: 100vh;

  @media (max-height: 600px) {
    height: 600px;
    overflow: auto;
  }

  @media (max-width: 600px) {
    height: auto;
    overflow: auto;
  }

  #app {
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .section {
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    height: calc(100% - 100px);
    overflow: hidden;

    @media (max-width: 600px) {
      position: relative;
      top: auto;
      left: auto;
      height: auto;
    }
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}
</style>