import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            data: {
                areaNumber: 1,
                totalAreas: 11,
                lockSite: false,
                sectionWidth: 0,
                containerScale: 1,
                pdfLocation: 'https://www.galp.com/corp/Portals/0/Recursos/Investidores/IMR2023',
                copies: {
                    title: {
                        pt: 'Relatório Integrado de Gestão 2023',
                        en: 'Integrated Management Report 2023'
                    },
                    index: {
                        pt: 'Índice',
                        en: 'Index'
                    },
                    downloads: {
                        pt: {
                            tit: 'Relatório Integrado de Gestão',
                            link1: {label: 'Standard', pdf:'/PT/RelatorioIntegradoAnual2023.pdf'},
                            link2: {label: 'High Quality', pdf:'/PT/RelatorioIntegradoAnual2023HQ.pdf'}
                        },
                        en: {
                            tit: 'Integrated Management Report',
                            link1: {label: 'Standard', pdf:'/EN/AnnualIntegratedReport2023.pdf'},
                            link2: {label: 'High Quality', pdf:'/EN/AnnualIntegratedReport2023HQ.pdf'}
                        }
                    },
                    download: {
                        pt: 'Ir para capítulo',
                        en: 'Access the Chapter',
                    },
                    paula: {
                        pt: {
                            name: 'Paula Amorim',
                            title: 'Presidente do Conselho de Administração',
                            message: 'As perspetivas para a Galp em 2024 e nos anos seguintes continuam a ser encorajadoras. Estas são apoiadas pelo nosso portefólio único, que inclui muitas oportunidades de valor a serem exploradas e desenvolvidas. A nossa gestão financeira rigorosa e responsável fará parte desse percurso, como sempre. A melhoria da nossa eficiência operacional proporciona-nos válidos recursos para continuarmos a investir em crescimento e transformação, garantindo em simultâneo que os nossos acionistas são recompensados de forma competitiva.',
                            button: 'Ver a mensagem na íntegra',
                            pdf: '/PT/RelatorioIntegradodeGestao.pdf#page=5'
                        },
                        en: {
                            name: 'Paula Amorim',
                            title: 'Chairman',
                            message: 'Galp’s outlook for 2024 and beyond remains encouraging. It is supported by our unique portfolio, which offers plenty of value opportunities to be de-risked and developed. As always, our responsible and rigorous financial management will be part of that journey. Improved operational efficiencies provide us with the resources to continue to invest in growth and transformation while ensuring competitive rewards for our shareholders.',
                            button: 'See message in full',
                            pdf:'/EN/IntegratedManagementReport.pdf#page=5'
                        }
                    },
                    filipe: {
                        pt: {
                            name: 'Filipe Silva',
                            title: 'CEO',
                            message: 'Competimos diariamente com as melhores empresas de energia a nível mundial, quase todas com uma dimensão muito superior à da Galp. O nosso sucesso e o nosso right to win dependem da rapidez das nossas ações. De forma a assegurar este sucesso, procuramos contratar e reter o melhor talento, servir melhor os nossos clientes, procurar os melhores parceiros e focar nos ativos mais competitivos mundialmente.',
                            button: 'Ver a mensagem na íntegra',
                            pdf: '/PT/RelatorioIntegradodeGestao.pdf#page=7'
                        },
                        en: {
                            name: 'Filipe Silva',
                            title: 'CEO',
                            message: 'We compete on a daily basis with the very best energy companies worldwide, almost all multiple times larger than Galp. Our success and our right to win depends on moving fast. To ensure this, we aim to hire and retain the best talent, serve our clients better, seek out the best partners, and focus on the most competitive assets worldwide.',
                            button: 'See message in full',
                            pdf:'/EN/IntegratedManagementReport.pdf#page=7'
                        }
                    },
                    menu: {
                        index: { pt: 'Relatório Integrado de Gestão', en: 'Integrated Management Report' },
                        rel: { pt: 'Relatórios PDF', en: 'PDF Reports' },
                        chaps:
                        {
                            pt: [
                                { num: 1, name: 'Mundo <br />Galp' },
                                { num: 2, name: 'Enquadramento<br />estratégico' },
                                { num: 3, name: 'Pilares de<br />negócio' },
                                { num: 4, name: 'Desempenho<br />financeiro' },
                                { num: 5, name: 'Governo<br />societário' },
                                { num: 6, name: 'Proposta de aplicação<br />de resultados' },
                                { num: 7, name: 'Declaração' }
                            ],
                            en: [
                                { num: 1, name: 'Galp<br />world' },
                                { num: 2, name: 'Strategic<br />framework' },
                                { num: 3, name: 'Business<br />pillars' },
                                { num: 4, name: 'Financial<br />performance' },
                                { num: 5, name: 'Corporate<br />governance' },
                                { num: 6, name: 'Proposal for the<br />allocation of results' },
                                { num: 7, name: 'Cautionary<br />statment' }
                            ]
                        },
                        rels: {
                            pt: [
                                {name: '<span>Relatório</span><br />Relatório Integrado Anual', pdf: '#'},
                                {name: '<span>Parte I</span><br />Relatório Integrado de Gestão', pdf: '/PT/RelatorioIntegradodeGestao.pdf'},
                                {name: '<span>Parte II</span><br />Jornada de Sustentabilidade', pdf: '/PT/JornadadeSustentabilidade.pdf'},
                                {name: '<span>PARTE III</span><br />Relatório do Governo Societário', pdf: '/PT/RelatoriodoGovernoSocietario.pdf'},
                                {name: '<span>Parte IV</span><br />Demonstrações Financeiras Consolidadas e individuais', pdf: '/PT/DemonstracoesFinanceirasConsolidadaseIndividuais.pdf'},
                                {name: '<span>Parte V</span><br />Anexos', pdf: '/PT/Anexos.pdf'},
                            ],
                            en: [
                                {name: '<span>Report</span><br />Annual Integrated Report', pdf: '#'},
                                {name: '<span>Part I</span><br />Integrated Management Report', pdf: '/EN/IntegratedManagementReport.pdf'},
                                {name: '<span>Part II</span><br />Sustainability Journey', pdf: '/EN/SustainabilityJourney.pdf'},
                                {name: '<span>Part III</span><br />Corporate Governance Report', pdf: '/EN/CorporateGovernanceReport.pdf'},
                                {name: '<span>Part IV</span><br />Consolidated and Individual Financial Statements', pdf: '/EN/ConsolidatedandIndividualFinancialStatements.pdf'},
                                {name: '<span>Part V</span><br />Appendices', pdf: '/EN/Appendices.pdf'}
                            ]
                        }
                    },
                    chap1: {
                        pt: {
                            title: '1. Mundo Galp',
                            pdf: '/PT/RelatorioIntegradodeGestao.pdf#page=9',
                            index: [
                                '<span>1.1</span> Os ativos da Galp no mundo',
                                '<span>1.2</span> Criação de valor',
                                '<span>1.3</span> A Galp nos mercados de capitais',
                                '<span>1.4</span> A nossa marca'
                            ]
                        },
                        en: {
                            title: '1. Galp world',
                            pdf: '/EN/IntegratedManagementReport.pdf#page=8',
                            index: [
                                '<span>1.1</span> Galp’s assets worldwide',
                                '<span>1.2</span> Value creation',
                                '<span>1.3</span> Galp in the capital markets',
                                '<span>1.4</span> Our brand'
                            ]
                        }
                    },
                    chap2: {
                        pt: {
                            title: '2. Enquadramento estratégico',
                            pdf: '/PT/RelatorioIntegradodeGestao.pdf#page=16',
                            index: [
                                '<span>2.1</span> Como vemos a evolução do mercado da energia',
                                '<span>2.2</span> Criação de valor sustentável',
                                '<span>2.3</span> Abordagem ESG',
                                '<span>2.4</span> Como gerimos o risco'
                            ]
                        },
                        en: {
                            title: '2. Strategic framework',
                            pdf: '/EN/IntegratedManagementReport.pdf#page=15',
                            index: [
                                '<span>2.1</span> How we see the energy market evolving',
                                '<span>2.2</span> Creating sustainable value',
                                '<span>2.3</span> Approach to ESG',
                                '<span>2.4</span> How we manage risk'
                            ]
                        }
                    },
                    chap3: {
                        pt: {
                            title: '3. Pilares de negócio',
                            pdf: '/PT/RelatorioIntegradodeGestao.pdf#page=29',
                            index: [
                                '<span>3.1</span> Destaques 2023',
                                '<span>3.2</span> Upstream',
                                '<span>3.3</span> Industrial & Midstream',
                                '<span>3.4</span> Commercial',
                                '<span>3.5</span> Renewables & New Businesses'
                            ]
                        },
                        en: {
                            title: '3. Business pillars',
                            pdf: '/EN/IntegratedManagementReport.pdf#page=28',
                            index: [
                                '<span>3.1</span> 2023 Highlights',
                                '<span>3.2</span> Upstream',
                                '<span>3.3</span> Industrial & Midstream',
                                '<span>3.4</span> Commercial',
                                '<span>3.5</span> Renewables & New Businesses'
                            ]
                        }
                    },
                    chap4: {
                        pt: {
                            title: '4. Desempenho financeiro',
                            pdf: '/PT/RelatorioIntegradodeGestao.pdf#page=62',
                            index: [
                                '<span>4.1</span> Destaques de 2023',
                                '<span>4.2</span> Desempenho operacional',
                                '<span>4.3</span> Rendimentos consolidados',
                                '<span>4.4</span> Investimento',
                                '<span>4.5</span> Cash flow',
                                '<span>4.6</span> Situação financeira'
                            ]
                        },
                        en: {
                            title: '4. Financial performance',
                            pdf: '/EN/IntegratedManagementReport.pdf#page=61',
                            index: [
                                '<span>4.1</span> 2023 highlights',
                                '<span>4.2</span> Operating performance',
                                '<span>4.3</span> Consolidated income',
                                '<span>4.5</span> Cash flow',
                                '<span>4.6</span> Financial position'
                            ]
                        }
                    },
                    chap5: {
                        pt: {
                            title: '5. Governo societário',
                            pdf: '/PT/RelatorioIntegradodeGestao.pdf#page=69',
                            index: [
                                '<span>5.1</span> Modelo de governo',
                                '<span>5.2</span> Órgãos sociais',
                                '<span>5.3</span> Política de remuneração',
                                '<span>5.4</span> Conformidade com o Código de Governo Societário'
                            ]
                        },
                        en: {
                            title: '5. Corporate Governance',
                            pdf: '/EN/IntegratedManagementReport.pdf#page=68',
                            index: [
                                '<span>5.1</span> Governance model',
                                '<span>5.2</span> Corporate bodies',
                                '<span>5.3</span> Remuneration policy',
                                '<span>5.4</span> Compliance with the corporate governance code',
                            ]
                        }
                    },
                    chap6: {
                        pt: {
                            title: '6. Proposta de aplicação de resultados',
                            pdf: '/PT/RelatorioIntegradodeGestao.pdf#page=79',
                        },
                        en: {
                            title: '6. Proposal for the allocation of results',
                            pdf: '/EN/IntegratedManagementReport.pdf#page=78',
                        }
                    },
                    chap7: {
                        pt: {
                            title: '7. Declaração',
                            pdf: '/PT/RelatorioIntegradodeGestao.pdf#page=81',
                        },
                        en: {
                            title: '7. Cautionary statement',
                            pdf: '/EN/IntegratedManagementReport.pdf#page=80',
                        }
                    },
                    navmessages: {
                        pt: [
                            'Início',
                            'Mensagem da Chairman',
                            'Mensagem do CEO',
                            'Mundo Galp',
                            'Enquadramento estratégico',
                            'Pilares de negócio',
                            'Desempenho financeiro',
                            'Governo societário',
                            'Proposta de aplicação de resultados',
                            'Declaração',
                            'Índice'
                        ], 
                        en: [
                            'Home',
                            'Message from the Chairman',
                            'Message from the CEO',
                            'Galp world',
                            'Strategic framework',
                            'Business pillars',
                            'Financial performance',
                            'Corporate governance',
                            'Proposal for the allocation of results',
                            'Cautionary statment',
                            'Index'
                        ]
                    }
                }
            },
        }
    },

    getters: {
        AppData(state) { return state.data; },
    },

    actions: {
        updateAppData({ commit }, data) { commit('updateAppData', data); },
    },

    mutations: {
        updateAppData(state, data) {
            switch (data.prop) {
                case 'aid': state.data.areaNumber = data.value;
                    break;
                case 'lock': state.data.lockSite = data.value;
                    break;
                case 'sectionWidth': state.data.sectionWidth = data.value;
                    break;
                case 'containerScale': state.data.containerScale = data.value;
                    break;
            }
        }
    }
});

export default store;