<template>
  <section class="section" id="chapter5" ref="section">
    <div class="container">
      <div class="content" ref="content">
        <div class="letter le" ref="le">
          <div class="main" ref="main">
            <img class="mask" src="@/assets/images/energy_letter_g.png" />
            <div class="overlay"></div>
            <div class="box"><img ref="boximage" class="image" src="@/assets/images/energy_letter_g_image.jpg" /></div>
          </div>
          <img class="mobile" src="@/assets/images/mobile_section5.png" />
          <div class="text">
            <p class="small" ref="text1">DELIVERING</p>
            <p class="big" ref="text2">Energy</p>
          </div>
          <div class="contents">
            <div class="col" ref="col1">
              <div class="subtitle">for people</div>
              <div class="title">{{ AppData.copies.chap5[$route.query.l ? $route.query.l : 'pt'].title }}</div>
              <p></p>
            </div>
            <div class="col" ref="col2">
              <ul v-if="AppData.copies.chap5[$route.query.l ? $route.query.l : 'pt'].index.length > 0">
                <li v-for="idx in AppData.copies.chap5[$route.query.l ? $route.query.l : 'pt'].index" :key="idx"
                  v-html="idx"></li>
              </ul>
              <a :href="AppData.pdfLocation + AppData.copies.chap5[$route.query.l ? $route.query.l : 'pt'].pdf" target="_blank" ref="link">
                <span>{{ AppData.copies.download[$route.query.l ? $route.query.l : 'pt'] }}</span>
                <svg id="a" data-name="Isolation Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.69 28.69">
                  <path
                    d="M14.35,0c5.79,0,14.34,8.55,14.34,14.34,0,5.79-8.55,14.34-14.35,14.34-5.79,0-14.34-8.55-14.34-14.35C0,8.55,8.55,0,14.35,0"
                    style="fill: #f26522; stroke-width: 0px;" />
                  <path
                    d="M7.55,12.92c.39,1.14,1.17,2.13,1.96,3.03.91,1.03,1.94,1.98,3.07,2.76.44.3.95.62,1.47.78s1.14-.21,1.6-.49c1.17-.7,2.22-1.64,3.15-2.63s1.89-2.13,2.34-3.45c.32-.92-1.14-1.31-1.45-.4-.42,1.22-1.46,2.3-2.35,3.19-.51.51-1.06,1.01-1.64,1.44-.26.19-.53.38-.81.54-.14.08-.29.15-.43.24-.06.04-.36.1-.12.1s-.05-.06-.12-.1c-.14-.09-.29-.15-.43-.24-.28-.17-.55-.35-.81-.54-.58-.44-1.12-.93-1.64-1.44-.89-.89-1.93-1.97-2.35-3.19-.31-.91-1.77-.52-1.45.4h0Z"
                    style="fill: #fff; stroke-width: 0px;" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      animations: []
    }
  },

  computed: { ...mapGetters(['AppData']) },

  mounted: function () {
    window.addEventListener("resize", this.resize);
    this.reset();
    this.resize();
  },

  methods: {

    reset(_duration) {
      if (window.innerWidth < 760) return;
      gsap.to(this.$refs.main, { x: window.innerWidth, duration: _duration, opacity: 0 });
      gsap.to(this.$refs.boximage, { x: -396, scale: 10, duration: _duration, opacity: 0 });
      gsap.to(this.$refs.text1, { x: window.innerWidth, duration: _duration, opacity: 0 });
      gsap.to(this.$refs.text2, { x: window.innerWidth, duration: _duration, opacity: 0 });
      gsap.to(this.$refs.col1, { x: window.innerWidth, duration: _duration, opacity: 0 });
      gsap.to(this.$refs.col2, { x: window.innerWidth, duration: _duration, opacity: 0 });
    },

    animateIn() {
      if (window.innerWidth < 760) return;
      this.animations.push(gsap.to(this.$refs.main, { x: 0, duration: 1, opacity: 1, ease: "power1.out" }));
      this.animations.push(gsap.to(this.$refs.boximage, { x: -396, scale: 1, duration: 1, opacity: 1, ease: "power1.out" }));
      this.animations.push(gsap.to(this.$refs.text1, { x: 0, duration: 1, opacity: 1, delay: 0.2, ease: "power1.out" }));
      this.animations.push(gsap.to(this.$refs.text2, { x: 0, duration: 1, opacity: 1, delay: 0.4, ease: "power1.out" }));
      this.animations.push(gsap.to(this.$refs.col1, { x: 0, duration: .5, opacity: 1, delay: 0.6, ease: "power1.out" }));
      this.animations.push(gsap.to(this.$refs.col2, { x: 0, duration: .5, opacity: 1, delay: 0.7, ease: "power1.out" }));
      this.$refs.section.style.zIndex = 1;
    },

    animateOut(reverse) {
      if (window.innerWidth < 760) return;
      if (reverse < 0) {
        this.reset(1);
      } else {
        gsap.to(this.$refs.main, { x: -window.innerWidth, duration: 1, opacity: 0 });
        gsap.to(this.$refs.boximage, { x: - window.innerWidth, scale: 1, duration: 1, opacity: 0 });
        gsap.to(this.$refs.text1, { x: - window.innerWidth, duration: 1, opacity: 0 });
        gsap.to(this.$refs.text2, { x: - window.innerWidth, duration: 1, opacity: 0 });
        gsap.to(this.$refs.col1, { x: - window.innerWidth / 2, duration: 1, opacity: 0 });
        gsap.to(this.$refs.col2, { x: - window.innerWidth / 2, duration: 1, opacity: 0 });
      }
    },

    resize() {
      if (window.innerWidth < 760) return;
      this.$refs.content.style.transform = "translateY(-50%) scale(" + this.AppData.containerScale + ")";
      this.$refs.section.querySelector('.container').style.maxWidth = this.AppData.sectionWidth + 'px';
    }
  }
}
</script>

<style lang="scss">
#chapter5 {

  @media (max-height: 760px) {
    height: 100vh;
  }

  .container {
    margin: auto;
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;

    @media (max-width: 760px) {
      height: calc(100vh - 70px);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      width: 85%;
    }

    .content {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      color: #fff;
      transform-origin: left center;
      margin-top: -60px;

      @media (max-height: 760px) {
        margin-top: -40px;
      }

      @media (max-width: 760px) {
        position: relative;
        top: auto;
        transform: none !important;
        margin-top: 0;
      }
    }
  }

  .mobile {
    display: none;
    position: relative;

    @media (max-width: 760px) {
      display: block;
      position: relative !important;
      margin-bottom: 50px;
    }

  }

  .letter {
    position: relative;
    transform: scale(2);
    transform-origin: left;

    @media (max-width: 760px) {
      transform: none;
    }

    .main {
      position: relative;
      width: 441px;
      height: 476px;

      @media (max-width: 760px) {
        display: none;
      }
    }

    .text {
      position: absolute;
      top: 50%;
      left: 3px;
      color: #F26522;
      font-family: 'Canaro Bold';
      text-transform: uppercase;
      z-index: 100;
      transform: translateY(calc(-50% - 27px));

      @media (max-width: 760px) {
        position: relative;
        top: auto;
        transform: none !important;
        opacity: 1 !important;
      }

      p {
        font-size: 53px;
        margin-left: -2px;

        &.big {
          font-size: 188px;
          margin-left: -10px;
          margin-top: -13px;
        }

        @media (max-width: 760px) {
          display: none;
        }
      }
    }

    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      background: #231F20;
      opacity: .05;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    .box {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 99%;
      height: 99%;
      overflow: hidden;
      transform: translate(-50%, -50%);

    }

    img {
      position: absolute;
      left: 0;
      top: 0;

      &.mask {
        z-index: 3;
      }

      &.image {
        z-index: 0;
        left: 50%;
        top: 50%;
        transform: translate(-396px, -234px) scale(1.06);
        height: 100%;
        z-index: 1;
      }
    }

    .contents {
      position: absolute;
      top: calc(50% + 70px);
      left: calc(100% + 200px);
      display: flex;
      justify-content: space-between;
      color: #000;
      font-family: 'Canaro';
      min-height: 165px;

      @media (max-width: 760px) {
        position: relative;
        top: auto;
        left: auto;
        display: block;
      }

      .col {
        min-width: 400px;
        position: relative;

        &:first-child {
          margin-right: 70px;
        }

        @media (max-width: 760px) {
          transform: none !important;
          opacity: 1 !important;
          min-width: auto;
        }
      }

      .subtitle {
        color: #F26522;
        font-family: 'Canaro Bold';
        font-size: 37px;
        text-transform: uppercase;
        margin-bottom: 25px;

        @media (max-width: 760px) {
          display: none;
        }
      }

      .title {
        font-family: 'Canaro Bold';
        font-size: 28px;
        margin-bottom: 5px;

        @media (max-width: 760px) {
          margin-bottom: 15px;
        }
      }

      p {
        font-size: 14px;
        line-height: 20px;
      }

      ul {
        li {

          span {
            font-family: 'Canaro Bold';
            padding-right: 25px;
            width: 45px;
            display: inline-block;
          }

          font-family: 'Canaro';
          font-size: 14px;
          margin-bottom: 10px;
          max-width: 240px;
          display: flex;
          line-height: 18px;
        }
      }

      a {
        color: #000;
        font-family: 'Canaro';
        font-size: 16px;
        text-decoration: none;
        display: flex;
        align-items: center;
        margin-top: 80px;
        position: absolute;
        left: 0;
        bottom: 0;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0px;
          width: 81%;
          height: 1px;
          background: #000;
        }

        svg {
          height: 23px;
          margin-left: 10px;
        }

        @media (max-width: 760px) {
          position: relative;
          margin-top: 40px;
          display: inline-flex;
        }
      }
    }
  }
}
</style>
